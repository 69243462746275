import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
`;

export const HeaderWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 40px;
  z-index: 10;
  /* width: calc(100vw - 120px); */
`;

export const Content = styled.div`
  width: calc(100vw - 80px);
  margin-left: 82px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-left: 0;
  }
`;
