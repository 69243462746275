import React from 'react';

import { Footer, Header, Navigation, MobileNav } from 'components';

import { Container, HeaderWrap, Content } from './styled';

const MainLayout = ({ children, navColor, headerColor }) => {
  return (
    <Container>
      <HeaderWrap>
        <Header color={headerColor ? headerColor : 'white'} />
      </HeaderWrap>
      <Navigation colorScheme={navColor} />
      <Content>
        {children}
        <Footer />
      </Content>
      <MobileNav />
    </Container>
  );
};

export default MainLayout;
