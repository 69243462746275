import styled from 'styled-components';

export const Content = styled.div`
  transform: translateY(-82px);
  h2 {
    font-size: 30px;
    transform: translateX(42px);
    margin: 0 0 28px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    transform: translateY(0);
  }
`;
