import React from 'react';
import { graphql } from 'gatsby';

import MainLayout from 'layouts/MainLayout';
import { DarkScheme } from 'helpers/navColors';
import { Albums, Banner, Features, Guides, SEO } from 'components';
import { InnerWrap, HeaderWrap } from 'components/common/Containers/styled';
import { Content } from 'templates/Artist/styled';

const AuthorTemplate = ({ data }) => {
  const { strapiAuthors, features, guides, albums } = data;
  const {
    englishName,
    japaneseName,
    bio,
    bannerBackgroundColor,
    bannerTextColor,
  } = strapiAuthors;

  const bannerConfig = {
    title: englishName,
    bio: bio,
    type: 'person',
    isAuthor: true,
  };

  return (
    <MainLayout navColor={DarkScheme} headerColor={bannerTextColor}>
      <SEO
        title={`${englishName} | Author`}
        description={`Articles by ${englishName}`}
      />

      <Banner {...bannerConfig} />

      <Content>
        <InnerWrap>
          {features.edges.length > 0 && (
            <HeaderWrap>
              <h3>Features</h3>
            </HeaderWrap>
          )}
          {features.edges.length > 0 && <Features {...features} />}

          {guides.edges.length > 0 && (
            <HeaderWrap>
              <h3>Genre Guides</h3>
            </HeaderWrap>
          )}
          {guides.edges.length > 0 && <Guides {...guides} />}

          {albums.edges.length > 0 && (
            <HeaderWrap>
              <h3>Reccs</h3>
            </HeaderWrap>
          )}
          {albums.edges.length > 0 && <Albums {...albums} />}
        </InnerWrap>
      </Content>
    </MainLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    strapiAuthors(slug: { eq: $slug }) {
      englishName
      japaneseName
      bio
      slug
    }
    features: allStrapiFeatures(
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: publishedDate, order: DESC }
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          artists {
            englishName
          }
          authors {
            englishName
          }
          category {
            name
          }
          coverImage {
            url
          }
        }
      }
    }
    guides: allStrapiGenreGuides(
      filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: publishedDate, order: DESC }
    ) {
      edges {
        node {
          title
          publishedDate(formatString: "MMMM Do YYYY")
          slug
          coverImage {
            url
          }
          albumRecommendations {
            albumName
            artistName
            coverImage {
              url
            }
          }
        }
      }
    }
    albums: allStrapiAlbums(
      filter: { author: { slug: { eq: $slug } } }
      sort: { fields: releaseDate, order: DESC }
    ) {
      edges {
        node {
          englishTitle
          slug
          artists {
            englishName
          }
          genre {
            name
          }
          releaseDate(formatString: "MMMM D YYYY")
          artwork {
            url
          }
        }
      }
    }
  }
`;

export default AuthorTemplate;
